import { Controller } from '@hotwired/stimulus';
import { trackProductAnalytics } from 'common/services/product_analytics/trackProductAnalytics';

// Tracks product analytics events by utilizing trackProductAnalytics helper.
// If `browserEvent` is not defined, tracking will be called on `connect`.
//
// Ex:
// <div
//   data-controller="track-product-analytics"
//   data-track-product-analytics-browser-event-value="click"
//   data-track-product-analytics-async-tracking-value="false"
//   data-track-product-analytics-name-value="Landing Page Entrypoint Clicked"
//   data-track-product-analytics-properties-value="{ ...payload... }"
// >
//   ...children...
// </div>

export default class extends Controller {
  // set asyncTracking to false for links that navigate to another page in the
  // same tab. By default asyncTracking is true.
  static values = {
    browserEvent: String,
    name: String,
    properties: Object,
    asyncTracking: Boolean,
    siteTracking: Boolean,
    once: Boolean,
  };

  initialize() {
    if (!this.hasAsyncTrackingValue) {
      this.asyncTrackingValue = true;
    }
    if (!this.hasSiteTrackingValue) {
      this.siteTrackingValue = false;
    }
    this._track = this._track.bind(this);
    this._isBusy = false;
  }

  connect() {
    if (this.hasBrowserEventValue) {
      this.element.addEventListener(this.browserEventValue, this._track);
    } else {
      this._track();
    }
  }

  disconnect() {
    if (this.hasBrowserEventValue) {
      this.element.removeEventListener(this.browserEventValue, this._track);
    }
  }

  _track(event) {
    if (this._isBusy) return;

    this._isBusy = true;

    // track analytics synchronously. E.g.: clicking on a link that navigates to
    // another page
    if (
      this.browserEventValue === 'click' &&
      this.element.href &&
      this.asyncTrackingValue === false
    ) {
      event.preventDefault();
      trackProductAnalytics(this.nameValue, this.propertiesValue, {
        siteTracking: this.siteTrackingValue,
      }).finally(() => {
        window.location.href = this.element.href;
      });
      return;
    }

    // track analytics asynchronously for other events
    setTimeout(() => {
      trackProductAnalytics(this.nameValue, this.propertiesValue, {
        siteTracking: this.siteTrackingValue,
      });
    }, 0);

    if (this.onceValue && this.hasBrowserEventValue) {
      // Remove the event listener to prevent tracking
      this.element.removeEventListener(this.browserEventValue, this._track);
    }

    this._isBusy = false;
  }
}
